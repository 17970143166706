import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: "/index",
  },
  {
    path: "/index",
    name: "index",
    component: () => import("../view/index"),
    meta: {
      title: "成长无忧疫苗综合保障计划",
      isBack: false,
      keepAlive: true,
    },
  },
  {
    path: "/rightsAndInterests",
    name: "rightsAndInterests",
    component: () => import("../view/rightsAndInterests"),
    meta: {
      title: "保障权益",
    },
  },
  {
    path: "/insurePackage",
    name: "insurePackage",
    component: () => import("../view/insurePackage/pages/insure/index"),
    meta: {
      title: "投保流程",
      isBack: false,
      keepAlive: true,
    },
  },
  {
    path: "/confirm",
    name: "confirm",
    component: () => import("../view/insurePackage/pages/insure/confirmOrder"),
    meta: {
      title: "收银台",
    },
  },
  {
    path: "/material",
    name: "material",
    component: () => import("../view/material"),
    meta: {
      title: "产品资料",
    },
  },
  {
    path: "/fileList",
    name: "fileList",
    component: () => import("../view/fileList"),
    meta: {
      title: "产品资料",
    },
  },
  {
    path: "/pdf",
    name: "pdf",
    component: () => import("../view/pdf"),
    meta: {
      title: "查看",
    },
  },
  {
    path: "/paySuccess",
    name: "paySuccess",
    component: () => import("../view/paySuccess"),
    meta: {
      title: "投保成功",
    },
  },
  {
    path: "/payError",
    name: "payError",
    component: () => import("../view/payError"),
    meta: {
      title: "支付失败",
    },
  },
  {
    path: "/insuranceFailure",
    name: "insuranceFailure",
    component: () => import("../view/insuranceFailure"),
    meta: {
      title: "投保失败",
    },
  },
  {
    path: "/city",
    name: "city",
    component: () => import("../view/city"),
    meta: {
      title: "选择疫苗接种点",
    },
  },
  {
    path: "/insuranceNotice",
    name: "insuranceNotice",
    component: () => import("../view/insuranceNotice"),
    meta: {
      title: "投保须知",
    },
  },
  {
    path: "/exemptionClause",
    name: "exemptionClause",
    component: () => import("../view/exemptionClause"),
    meta: {
      title: "责任免除条款",
    },
  },
  {
    path: "/orderList",
    name: "exemptionClause",
    component: () => import("../view/orderList"),
    meta: {
      title: "订单列表",
    },
  },
  {
    path: "/surrender",
    name: "surrender",
    component: () => import("../view/surrender"),
    meta: {
      title: "退保信息",
    },
  },
  {
    path: "/surrenderStatus",
    name: "surrenderStatus",
    component: () => import("../view/surrenderStatus"),
    meta: {
      title: "退保结果",
    },
  },
  {
    path: "/invoice",
    name: "invoice",
    component: () => import("../view/invoice"),
    meta: {
      title: "发票信息填写",
    },
  },
  {
    path: "/invoiceStatus",
    name: "invoiceStatus",
    component: () => import("../view/invoiceStatus"),
    meta: {
      title: "申请发票结果",
    },
  },
  {
    path: "/policyDetail",
    name: "policyDetail",
    component: () => import("../view/policyDetail"),
    meta: {
      title: "保单详情",
    },
  },
  {
    path: "/:name/word",
    name: "word",
    component: () => import("../view/word/index.vue"),
    meta: {
      title: "查看",
    },
  },
]
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
})

export default router
